import React from 'react'

import BlogLayout from 'layouts/Blog'
import SEO from 'components/shared/SEO'
import { FormInput } from 'components/shared/Forms'

export default class Feedback extends React.Component {
  render() {
    return (
      <BlogLayout location={this.props.location}>
        <SEO />
        <div id="feedback-page">
          <div className="content-wrap">
            <section className="page-title form-title">
              <h2>Give us feedback</h2>
            </section>
            <form
              role="feedback-form"
              className="feedback-form"
              id="feedback-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <section className="form-page-container">
                <div className="auth-page-subcontainer">
                  <div className="auth-page-flex">
                    <FormInput label="First Name">
                      <input
                        type="fname"
                        name="fname"
                        autoFocus
                        required
                        id="fname"
                        maxLength="250"
                      />
                    </FormInput>

                    <FormInput label="Email (optional)">
                      <input type="email" name="email" id="email" />
                    </FormInput>

                    <FormInput label="Feedback">
                      <textarea
                        type="feedback"
                        name="feedback"
                        required
                        rows="5"
                        id="feedback"
                      />
                    </FormInput>

                    <section className="feedback-page-submit">
                      <button className="cta-btn" type="submit" value="Submit">
                        Send feedback
                      </button>
                    </section>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </div>
      </BlogLayout>
    )
  }
}
